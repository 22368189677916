import * as React from 'react';
import { Component, ComponentClass } from 'react';
import styled, { css } from 'styled-components';
import { color, fontSize, space } from '@dozuki/web-js/primitives';
import { CheckDefault, MinusDefault, MinusLarge, CheckLarge } from './checkbox-icons';

interface CheckboxContainerProps {
   disabled: boolean;
}

const CheckboxContainer = styled.label<CheckboxContainerProps>`
   display: inline-flex;
   align-items: center;
   position: relative;
   cursor: pointer;

   ${props =>
      props.disabled &&
      css`
         cursor: auto;

         ${CheckboxLabel} {
            color: ${color.gray[400]};
         }
      `};
`;

interface CheckboxElemProps {
   filled: boolean;
   elemSize: CheckboxSize;
   disabled: boolean;
}

const CheckboxElem = styled.input<CheckboxElemProps>`
   appearance: none;
   margin: 0;
   width: ${props => (props.elemSize === CheckboxSize.Default ? '1rem' : '1.25rem')};
   height: ${props => (props.elemSize === CheckboxSize.Default ? '1rem' : '1.25rem')};
   min-width: ${props => (props.elemSize === CheckboxSize.Default ? '1rem' : '1.25rem')};

   background-color: ${props => (props.filled ? color.blue[500] : color.white)};
   border: 0.0625rem solid;
   border-radius: 0.25rem;
   border-color: ${props => (props.filled ? color.blue[600] : color.gray[300])};
   cursor: pointer;

   ${props =>
      props.disabled &&
      css<CheckboxElemProps>`
         cursor: auto;
         background-color: ${props => (props.filled ? color.blue[200] : color.gray[50])};
         border-color: ${props => (props.filled ? color.blue[200] : color.gray[200])};
      `};

   :focus {
      outline: none;
   }

   ::-ms-check {
      display: none;
   }
`;

interface CheckboxLabelProps {
   elemSize: CheckboxSize;
}

const CheckboxLabel = styled.span<CheckboxLabelProps>`
   font-size: ${props => (props.elemSize === CheckboxSize.Default ? fontSize.md : fontSize.lg)};
   margin-left: ${space[2]};
   color: ${color.gray[800]};
`;

const StyledIcon = styled.svg`
   position: absolute;
   pointer-events: none;
`;

export enum CheckboxSize {
   Default = 'default',
   Large = 'large',
}

type IconMap = {
   [key in CheckboxSize]: ComponentClass;
};

interface CheckboxProps {
   checked: boolean;
   onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
   value?: string;
   disabled?: boolean;
   indeterminate?: boolean;
   label?: string;
   size?: CheckboxSize;
}

const noop = () => {};
class Checkbox extends Component<CheckboxProps> {
   static defaultProps: CheckboxProps = {
      checked: false,
      onChange: noop,
      value: '',
      disabled: false,
      indeterminate: false,
      label: null,
      size: CheckboxSize.Default,
   };

   checkIcons: IconMap = {
      [CheckboxSize.Default]: CheckDefault,
      [CheckboxSize.Large]: CheckLarge,
   };

   minusIcons: IconMap = {
      [CheckboxSize.Default]: MinusDefault,
      [CheckboxSize.Large]: MinusLarge,
   };

   get iconComponent() {
      const { checked, indeterminate, size } = this.props;

      if (indeterminate) {
         return StyledIcon.withComponent(this.minusIcons[size]);
      } else if (checked) {
         return StyledIcon.withComponent(this.checkIcons[size]);
      }

      return null;
   }

   render() {
      const { checked, value, disabled, onChange, indeterminate, label, size, ...props } =
         this.props;

      const CheckboxIcon = this.iconComponent;
      return (
         <CheckboxContainer {...props} disabled={disabled}>
            <CheckboxElem
               type="checkbox"
               filled={checked || indeterminate}
               checked={checked}
               value={value}
               disabled={disabled}
               elemSize={size}
               onChange={onChange}
            />
            {label && <CheckboxLabel elemSize={size}>{label}</CheckboxLabel>}
            {CheckboxIcon && <CheckboxIcon />}
         </CheckboxContainer>
      );
   }
}

export default Checkbox;
