import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { color, borderRadius, breakpoint } from '@dozuki/web-js/primitives';
import Button, { ButtonSize } from '../Button/button';

// Since there's no base interface for icons we use JSX.Element
export type GroupedButtonProps = {
   content: string | JSX.Element;
   value: string;
};

export type ButtonGroupType = 'default' | 'secondary';

export interface ButtonGroupProps {
   buttons: Array<GroupedButtonProps>;
   initialValue?: string;
   type?: ButtonGroupType;
   size?: ButtonSize;
   onChange?: (value: string) => void;
}

const GroupedButton = styled(Button)<{ selected: boolean; buttonType: ButtonGroupType }>`
   margin: 0;
   border-radius: 0;

   /* Round corners of button group */
   :first-of-type {
      border-top-left-radius: ${borderRadius.md};
      border-bottom-left-radius: ${borderRadius.md};
   }
   :last-of-type {
      border-top-right-radius: ${borderRadius.md};
      border-bottom-right-radius: ${borderRadius.md};
   }

   :not(:first-of-type) {
      /* Overlap buttons so that borders are only 1px thick */
      margin-left: -1px;

      /* Add borders between buttons. Needed for secondary design */
      border-left-width: 1px;
      border-left-style: solid;
   }
   :not(:last-of-type) {
      /* Add borders between buttons. Needed for secondary design */
      border-right-width: 1px;
      border-right-style: solid;
   }

   &,
   :hover,
   :focus {
      border-color: ${props => props.buttonType === 'secondary' && color.gray[200]};

      /* Styling for a selected button. Similar to a 'primary' button */
      background-color: ${props => props.selected && color.gray[800]};
      color: ${props => props.selected && color.white};
      border-color: ${props => props.selected && color.gray[800]};
   }

   /* Use z-index to make hovered/selected buttons determine border color */
   :hover {
      z-index: 1;
   }
   z-index: ${props => props.selected && 2};
`;

const TextButton = styled(GroupedButton)`
   /* Fix line-height being 1px too low */
   padding-bottom: 1px;

   @media (max-width: ${breakpoint.md}) {
      width: 100%;

      :first-of-type {
         border-top-left-radius: ${borderRadius.md};
         border-top-right-radius: ${borderRadius.md};
         border-bottom-left-radius: 0;
      }
      :last-of-type {
         border-bottom-left-radius: ${borderRadius.md};
         border-bottom-right-radius: ${borderRadius.md};
         border-top-right-radius: 0;
      }

      :not(:first-of-type) {
         /* Overlap buttons so that borders are only 1px thick */
         margin-top: -1px;
         margin-left: 0;

         /* Add borders between buttons. Needed for secondary design */
         border-top-width: 1px;
         border-top-style: solid;
         border-left: ${props => props.buttonType === 'secondary' && 'none'};
      }
      :not(:last-of-type) {
         /* Add borders between buttons. Needed for secondary design */
         border-bottom-width: 1px;
         border-bottom-style: solid;
         border-right: ${props => props.buttonType === 'secondary' && 'none'};
      }
   }
`;

const ICON_BUTTON_SIZES = {
   default: '36px',
   small: '30px',
   large: '48px',
};

const IconButton = styled(GroupedButton)<{ size: ButtonSize }>`
   box-sizing: content-box;
   width: ${props => ICON_BUTTON_SIZES[props.size]};
   color: ${color.gray[500]};
   padding: 0;
`;

const ButtonGroupContainer = styled.div`
   display: flex;
   flex-wrap: wrap;
`;

const ButtonGroup: React.FC<ButtonGroupProps> = ({
   buttons,
   initialValue,
   size,
   type,
   onChange,
   ...props
}) => {
   const [selected, setSelected] = useState(initialValue);

   const handleOnClick = (value: string) => {
      if (value !== selected) {
         setSelected(value);
         onChange(value);
      }
   };

   return (
      <ButtonGroupContainer {...props}>
         {buttons.map(({ content, value }: GroupedButtonProps) => {
            const ButtonComponent = typeof content === 'string' ? TextButton : IconButton;

            return (
               <ButtonComponent
                  key={value}
                  buttonType={type}
                  size={size}
                  data-value={value}
                  type="button"
                  selected={value === selected}
                  onClick={() => handleOnClick(value)}
               >
                  {content}
               </ButtonComponent>
            );
         })}
      </ButtonGroupContainer>
   );
};

const defaultProps: ButtonGroupProps = {
   buttons: [],
   initialValue: '',
   size: 'default',
   type: 'default',
   onChange: (value: string) => {},
};
ButtonGroup.defaultProps = defaultProps;

export default ButtonGroup;
