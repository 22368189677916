import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { color, borderRadius, fontSize, space } from '@dozuki/web-js/primitives';
import * as corePrimitives from '@dozuki/web-js/primitives';

const DESKTOP = '(min-width: 1001px)';
const MOBILE_TABLET = 'only screen and (max-width: 1000px)';

const barHeight = '50px';

const BackLinkListElement = styled.div`
   position: relative;
   flex: 1;
   padding-right: 20px;
   overflow: hidden;
   text-overflow: ellipsis;
   min-width: 0;
`;

const BackLink = styled.a`
   display: inline-block;
   font-size: ${fontSize.md};
   line-height: ${barHeight};
   margin-right: auto;
   padding-left: ${space[6]};
   padding-right: ${space[4]};
   transition: background-color 0.1s, color 0.1s;

   @media ${DESKTOP} {
      border-bottom-left-radius: ${borderRadius.lg};
   }

   &:hover {
      background-color: rgba(0, 0, 0, 0.05);
      text-decoration: none;

      .svg-icon {
         opacity: 0.75;
      }
   }
`;

const BackLinkText = styled.span`
   padding-left: ${space[2]};
   color: ${color.gray[500]};
   text-decoration: none;
`;

const backPositionButtonPropTypes = {
   text: PropTypes.string.isRequired,
   mobileText: PropTypes.string.isRequired,
   href: PropTypes.string,
};

/**
 * @param {Object} params
 * @param [params.CustomIcon]
 * @param {string} params.text
 * @param {string} params.mobileText
 * @param {string} [params.href]
 * @param {Function} [params.onClick]
 */
const BackPositionButton = ({ text, mobileText, href, ...props }) => {
   const BackLinkIcon = styled(FontAwesomeIcon)`
      position: absolute;
      bottom: 50%;
      transform: translateY(50%);
      left: ${space[4]};
      pointer-events: none;
   `;

   return (
      <BackLinkListElement {...props}>
         {
            <BackLinkIcon
               className="svg-icon"
               icon={faArrowLeft}
               size={16}
               color={color.gray[400]}
            />
         }
         <BackLink href={href} data-testid="back-to-all-button">
            <BackLinkText className="visible-mobile-tablet-inline">{mobileText}</BackLinkText>
            <BackLinkText className="hidden-mobile-tablet">{text}</BackLinkText>
         </BackLink>
      </BackLinkListElement>
   );
};

BackPositionButton.propTypes = backPositionButtonPropTypes;

const ToolbarButtonListItem = styled.div`
   margin: 0;
`;

const PageNavigationButtonBase = styled.button`
   display: block;
   font-size: ${fontSize.md};
   line-height: 30px;
   margin-right: ${space[4]};
   padding-inline: ${space[4]};
   color: ${props => props.color};
   background-color: ${props => props.backgroundColor};
   cursor: pointer;

   &:hover {
      background-color: rgba(0, 0, 0, 0.05);
   }

   :visited {
      color: ${props => props.color};
   }
`;

const PageNavigationLink = styled(PageNavigationButtonBase.withComponent('a'))`
   line-height: ${barHeight};

   &:hover {
      text-decoration: none;

      .svg-icon {
         opacity: 0.75;
      }
   }
`;

const PageNavigationButton = styled(PageNavigationButtonBase)`
   border-radius: ${borderRadius.md};
   border: ${props => (props.includeButtonBorder ? `1px solid ${color.gray[200]}` : 'none')};
`;

const toolbarPositionButtonPropTypes = {
   children: PropTypes.node.isRequired,
};

const ToolbarPositionButton = ({
   children,
   onClick = () => {},
   type = 'submit',
   color = corePrimitives.color.gray[700],
   backgroundColor = corePrimitives.color.white,
   includeButtonBorder = true,
   href = '',
   ...props
}) => {
   let NavigationButton = href === '' ? PageNavigationButton : PageNavigationLink;

   return (
      <ToolbarButtonListItem {...props}>
         <NavigationButton
            color={color}
            backgroundColor={backgroundColor}
            href={href}
            includeButtonBorder={includeButtonBorder}
            onClick={onClick}
            type={type}
         >
            {children}
         </NavigationButton>
      </ToolbarButtonListItem>
   );
};

ToolbarPositionButton.propTypes = toolbarPositionButtonPropTypes;

const NavButtonContainer = styled.div`
   position: relative;
   margin: 0;
   background-color: ${color.gray[200]};
   -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

   @media ${DESKTOP} {
      border-bottom-left-radius: ${borderRadius.lg};
      border-bottom-right-radius: ${borderRadius.lg};
      overflow: hidden;
   }

   @media ${MOBILE_TABLET} {
      width: auto;
   }
`;

const PageNavigationBar = styled.div`
   display: flex;
   margin: 0;
   height: ${barHeight};
   align-items: center;

   @media ${MOBILE_TABLET} {
      border-radius: 0;
   }
`;

const navBarPropTypes = {
   children: PropTypes.node.isRequired,
};

const NavBar = ({ children, ...props }) => {
   return (
      <div {...props}>
         <NavButtonContainer>
            <PageNavigationBar>{children}</PageNavigationBar>
         </NavButtonContainer>
      </div>
   );
};

NavBar.propTypes = navBarPropTypes;

export { BackPositionButton, ToolbarPositionButton, NavBar };
